@import 'color.scss';
@import 'breakpoint.scss';
@import 'common.scss';
@import 'mixin.scss';

#mypage {
    #wrapper {
        padding-left: 0;
        -webkit-transition: all 0.5s ease;
        -moz-transition: all 0.5s ease;
        -o-transition: all 0.5s ease;
        transition: all 0.5s ease;

        height: calc(100vh - 67px); // dvh fallback
        height: calc(100dvh - 67px);
        transition: unset;

        @include media-breakpoint-down('sm') {
            &:not(.isCustomDetailPage) {
                height: calc(100vh - 115px); // dvh fallback
                height: calc(100dvh - 115px);
            }

            &.isCustomDetailPage {
                height: calc(100vh - 48px); // dvh fallback
                height: calc(100dvh - 48px);
            }
        }
    }

    #wrapper.toggled {
        padding-left: 300px;
    }

    .wrapper-content {
        height: $heightWithoutNav;

        @include media-breakpoint-down('lg') {
            overflow: auto;
        }
    }

    .scrollable-wrapper-content {
        height: unset !important;
    }

    #sidebar-wrapper {
        z-index: 1000;
        position: fixed;
        left: 300px;
        width: 0;
        height: 100%;
        margin-left: -300px;
        overflow-y: auto;
        background: #fff;
        -webkit-transition: all 0.5s ease;
        -moz-transition: all 0.5s ease;
        -o-transition: all 0.5s ease;
        transition: all 0.5s ease;
    }

    #sidebar-wrapper {
        box-shadow: inset -1px 0px 0px 0px #DDDDDD;
    }

    #wrapper.toggled #sidebar-wrapper {
        width: 300px;
    }

    #page-content-wrapper {
        width: 100%;
        position: sticky;
        padding: 15px;
    }

    #wrapper.toggled #page-content-wrapper {
        position: sticky;
        margin-right: -300px;
    }

    /* Sidebar Styles */

    .sidebar-col {
        .sidebar-transition {
            position: fixed;
            left: -350px;
            transition: left 500ms ease-in-out;
        }

        &:not(.isFilterSidebar) {
            @include sidebar-flex;
            transition: flex-basis 500ms ease-in-out;

            .sidebar-nav {
                &.default {
                    opacity: 1;
                    transition: opacity 0.2s ease-in;

                }

            }
        }

        .filter-action {
            width: 295px !important;
            left: 0;

            &:not(.sort-action) .btn {
                max-width: 120px !important;
            }
        }

        padding-top: 3px;
        height: $heightWithoutNav;

        &.isFilterSidebar {
            @include filter-sidebar-flex;
            transition: flex-basis 500ms ease-in-out;

            .sidebar-nav {
                padding-top: 0 !important;
                // height: calc($heightWithoutNav - 97px) !important;

                &.default {
                    opacity: 0;
                }

                height: calc($heightWithoutNav - 200px) !important;
            }

            .sidebar-brand {
                width: 100%;
                margin: 22px 0 !important;
            }



            .sidebar-transition {
                left: 0px !important;
                transition: left 500ms ease-in-out;
            }
        }

        &.defaultSidebar {
            .sidebar-transition {
                opacity: 1 !important;
                transition: left 500ms ease-in-out;
            }

        }


        p {
            font-size: 20px;
        }
    }

    .mypage-wrapper {

        .sidebar-nav {
            height: $heightWithoutNav2; //$mypageSidebarHeight;
            padding-top: 32px;
        }
    }

    .sidebar-nav {
        position: fixed;
        // top: $navHeight;
        width: 300px;
        height: 100vh; //$sidebarHeight;
        margin: 0;
        padding: 0px 20px 0px 28px;
        list-style: none;
        overflow: auto;

        li {
            color: $bg-color;
        }

        .icon {
            fill: $bg-color;
            width: 10px;

        }

        .subitem {
            .icon {
                width: 25px;
            }
        }
    }

    .sidebar-nav li {
        // text-indent: 15px;
    }

    .sidebar-nav li.last {
        margin-bottom: 100px;
    }

    .sidebar-nav li.category {
        text-indent: 15px;
        margin-bottom: 30px;
    }

    .sidebar-nav li.group {
        margin-bottom: 30px;

        p {
            font-weight: bold;
        }

        ul {
            list-style: none;
            padding: 0;

            li {
                // border-bottom: 1px solid $border-color;
                text-indent: 15px;

                svg {
                    width: 17px !important;
                }

                span {
                    font-weight: 500;
                    font-size: 15px;
                }

                &.active {
                    span {
                        background: linear-gradient(90deg, #E85EFF 0%, #2DD9FF 100%);
                        -webkit-background-clip: text;
                        -webkit-text-fill-color: transparent;
                        background-clip: text;
                        text-fill-color: transparent;
                    }
                }
            }

            li:not(.group) {
                // margin-bottom: 0px;
                // height: 40px;
                display: flex;
                flex-direction: column;
                // align-items: center;
                height: auto;
                margin-top: 2px;
                margin-bottom: 16px;
            }
        }
    }

    .sidebar-nav ul.ul-row {
        display: -webkit-box;
        overflow: auto;
    }

    .sidebar-nav li.group-row {
        // border: 1px solid $border-color;
        margin-right: 5px;

        &:last-child {
            margin-right: 0;
        }

        svg {
            width: 17px !important;
        }
    }

    .sidebar-nav li a {
        display: block;
        text-decoration: none;
        color: $bg-color;
        width: 100%;
        padding: 1px 5px;
    }

    .sidebar-nav li a:hover {
        text-decoration: none;
    }

    .sidebar-nav li a:active,
    .sidebar-nav li a:focus {
        text-decoration: none;
    }

    .sidebar-nav>.sidebar-brand {
        height: 65px !important;
        font-size: 18px;
        line-height: 60px;
        // margin: 20px 0 !important;
        // margin-bottom: 30px !important;
    }

    .sidebar-nav>.sidebar-brand a {
        color: #FFF;
    }

    .sidebar-nav>.sidebar-brand a:hover {
        color: #fff;
        background: none;
    }

    @include media-breakpoint-down('sm') {
        #wrapper.toggled {

            #sidebar-wrapper,
            .sidebar-nav {
                width: 100vw;
            }

        }
    }

    @media(min-width:768px) {
        #wrapper {
            padding-left: 300px;
        }

        #wrapper.toggled {
            padding-left: 0;
        }

        #sidebar-wrapper {
            width: 300px;
        }

        #wrapper.toggled #sidebar-wrapper {
            width: 0;
        }

        #page-content-wrapper {
            padding: 0px;
            position: relative;
        }

        #wrapper.toggled #page-content-wrapper {
            position: relative;
            margin-right: 0;
        }

        .sidebar-nav-toggler {
            display: none;
        }
    }

    // #sidebar-wrapper li.active>a:after {
    //     border-right: 17px solid #f4f3ef;
    //     border-top: 17px solid transparent;
    //     border-bottom: 17px solid transparent;
    //     content: "";
    //     display: inline-block;
    //     position: sticky;
    //     right: -1px;
    // }

    .sidebar-brand {
        padding: 18px 0px;
        margin-bottom: 20px;
    }

    .navbar .navbar-nav>li>a p {
        display: inline-block;
        margin: 0;
    }

    p {
        // font-size: 16px;
        line-height: 1.4em;
    }

    .navbar-default {
        background-color: #f4f3ef;
        border: 0px;
        border-bottom: 1px solid #DDDDDD;
    }

    btn-menu {
        border-radius: 3px;
        padding: 4px 12px;
        margin: 14px 5px 5px 20px;
        font-size: 14px;
        float: left;
    }
}

.filter-action {
    position: fixed;
    bottom: 20px;
    left: 0;
    right: 0;
    width: '100%';
    height: 50px;
    margin: 0;
    background-color: $bg-color-2;
}