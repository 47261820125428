@import 'color.scss';

@mixin profile-pic($width: 30px, $height: 30px, $padding: 0px) {
    border-radius: 150px;
    padding: $padding;

    img {
        width: $width;
        height: $height;
        object-fit: cover;
        object-position: center;
        border-radius: 150px;
    }

    svg {
        width: $width;
        height: $height;
    }
}

@mixin tag($width: 'fit-content') {
    width: $width;
    background-color: $tag-color;
    padding: 2px 10px;
    color: #fff;
    position: absolute;
    top: 0;
    left: 0;
    border-radius: 5px 0 5px 0;

    span {
        @include text-gradient($gradient-background);
        font-size: 18px;
        font-weight: 400;
        font-family: kardust;
        line-height: 23px;
    }
}

@mixin auth-form-input($important: null, $bg_image: linear-gradient(90deg, #797783, #797783), $focus_bg_image: linear-gradient(90deg, #e85eff, #2dd9ff)) {
    font-size: 18px $important;
    outline: 0 $important;
    border-radius: 15px $important;
    background: transparent $important;
    color: #ffffff $important;
    border: 1px solid rgba(217, 217, 217, 0.5) $important;
    box-shadow: 2px 1000px 1px #19162d inset $important;
    border-radius: 13px $important;
    font-family: KardustExpanded;

    &:focus,
    &.border-focus,
    &:hover {
        border: 1px solid $badge-bg-color $important;
    }

    &:focus,
    &.focus {
        &::placeholder {
            color: transparent $important;
        }
    }


    // &:focus-visible {
    //     background-image: none;
    // }

    &::placeholder {
        font-style: normal;
        font-weight: 400;
        font-size: 18px $important;
        color: #C4C4C4 $important;
    }
}

@mixin levias-auth-form-input($important: null) {
    font-size: 18px $important;
    outline: 0 $important;
    color: #000 $important;
    border: 1px solid #C4C4C4 $important;
    display: inline-block $important;
    position: relative $important;
    z-index: 0 $important;
    font-weight: 400;
    font-family: KardustExpanded;
    border-radius: 5px $important;

    &:focus,
    &.focus {
        &::placeholder {
            color: transparent $important;
        }
    }

    &::placeholder {
        font-style: normal;
        font-weight: 400;
        font-size: 18px $important;
        color: #C4C4C4 $important;
    }
}

@mixin levias-before-line($important: null) {
    content: ""$important;
    position: absolute $important;
    background-color: #FFFFFF $important;
    width: 25.29px $important;
    height: 1px $important;
    right: 0 $important;
    top: 50% $important;
}

input:focus::-webkit-input-placeholder {
    color: transparent;
}

input:focus:-moz-placeholder {
    color: transparent;
}

/* FF 4-18 */
input:focus::-moz-placeholder {
    color: transparent;
}

/* FF 19+ */
input:focus:-ms-input-placeholder {
    color: transparent;
}

/* IE 10+ */

@mixin flex-center {
    display: flex !important;
    justify-content: center;
    align-items: center;
}

@mixin sidebar-flex {
    -ms-flex: $sidebarFlexWidth;
    flex: $sidebarFlexWidth;
}

@mixin filter-sidebar-flex {
    -ms-flex: $sidebarFilterFlexWidth;
    flex: $sidebarFilterFlexWidth;
}

@mixin thumbnail($size: 'lg', $fixed: false) {
    width: 100%;
    object-fit: cover;
    object-position: center;
    width: 100%;

    @if $size =='md' {
        height: 171px;

        @if $fixed {
            width: 145px;
        }
    }

    @else if $size =='sm' {
        height: 85px;

        @if $fixed {
            width: 83px;
        }
    }

    @else {
        height: 325px;

        @if $fixed {
            width: 295px;
        }
    }


}

@mixin btn-custom($color: $btn-color, $border: $color, $borderWidth: 0px) {
    background-color: $color;
    border-color: $border;
    border-radius: 5px;
    border-width: $borderWidth;
    height: 50px;
    // min-width: 250px;
}

@mixin btn-center($offsetBottom: 2px) {
    // @include flex-center;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0px 0px $offsetBottom !important;
}

@mixin textbox($backgroundColor: transparent, $border: $color, $borderWidth: 1px) {
    background-color: $backgroundColor;
    height: 55px;

    mix-blend-mode: normal;
    opacity: 0.5;
    border: $borderWidth solid $border;
    backdrop-filter: blur(10px);
    /* Note: backdrop-filter has minimal browser support */

    border-radius: 15px;
    margin-bottom: 20px;
    color: #FFF !important;

    &:focus {
        &::placeholder {
            color: transparent important;
        }
    }
}

@mixin number-circle($size: 60px, $bg-color: $color, $color: #fff) {
    width: $size;
    height: $size;
    background-color: $bg-color;
    color: $color;
    font-size: 24px;
    @include flex-center();
    border-radius: 60px;
}

@mixin float-center {
    float: none;
    margin-left: auto;
    margin-right: auto;
}

@mixin col-res($size: xs, $width: 20) {

    position: relative;
    min-height: 1px;
    padding-right: 10px;
    padding-left: 10px;
    // width: $width * 1%;
    // float: left;

    @include media-breakpoint-up($size) {
        width: $width * 1%;
        float: left;
    }
}

@mixin assign-spacing($type, $position, $i) {
    @if ($position =='x') {
        #{$type}-left: #{$i}px !important;
        #{$type}-right: #{$i}px !important;
    }

    @else if ($position =='y') {
        #{$type}-top: #{$i}px !important;
        #{$type}-bottom: #{$i}px !important;
    }

    @else if ($position =='t') {
        #{$type}-top: #{$i}px !important;
    }

    @else if ($position =='b') {
        #{$type}-bottom: #{$i}px !important;
    }

    @else if ($position =='l') {
        #{$type}-left: #{$i}px !important;
    }

    @else if ($position =='r') {
        #{$type}-right: #{$i}px !important;
    }

    @else if ($position =='around') {
        #{$type}: #{$i}px !important;
    }
}

@mixin assign-spacing-res($size, $type, $position, $i) {
    @include media-breakpoint-up($size) {
        @include assign-spacing($type, $position, $i);
    }
}

@mixin generate-font-size($from: 10, $to: 40) {
    @for $i from $from through $to {
        &-#{$i} {
            font-size: #{$i}px !important;
        }
    }
}

@mixin fs-res($size, $from: 10, $to: 40) {
    @include media-breakpoint-up($size) {
        @include generate-font-size($from, $to);
    }
}

@mixin jp-fs-res($size, $from: 10, $to: 40) {
    @include media-breakpoint-up($size) {
        @include generate-font-size($from, $to);
    }
}

@mixin text-rainbow {
    background: linear-gradient(88.63deg, #FF0000 9.84%, #FF5C00 21.56%, #FFF800 31.54%, #00FF0A 45.86%, #00E0FF 58.01%, #AD00FF 71.03%, #FF0099 87.09%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
    width: fit-content;
}

@mixin text-gradient($gradient-color: #BF96FF) {
    background: $gradient-color;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
}

@mixin text-overflow($width: auto) {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    width: $width;
}

@mixin text-overflow-vertical($line: 2) {
    white-space: pre-wrap !important;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: $line;
    -webkit-box-orient: vertical;
}

@mixin gradient-icon-hover($img, $imgHover, $color: $color) {
    width: 26px;
    height: 26px;
    // background-color: $color;
    // -webkit-mask-image: $img;
    // -webkit-mask-repeat: no-repeat;
    // -webkit-mask-position: center;
    // -webkit-mask-size: contain;
    // mask-image: $img;
    // mask-repeat: no-repeat;
    // mask-position: center;
    // mask-size: contain;
    background-image: $img;
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;

    -webkit-transition: background-image 0.3s ease-in-out;
    transition: background-image .3s ease-in-out;

    &:hover,
    &:focus,
    &.hover {
        // -webkit-mask-image: none;
        // mask-image: none;
        background-image: $imgHover;

    }
}

@mixin border-box($gradient-color: $purple, $border-width: 1px, $border-radius: 15px, $opacity: 0.5) {
    padding: 10px;
    display: inline-block;
    position: relative;
    z-index: 0;
    padding: 16px 12px;

    &::before {
        content: "";
        position: absolute;
        z-index: -1;
        inset: 0;
        padding: $border-width;
        // opacity: $opacity;
        border-radius: $border-radius;
        background: $gradient-color !important;
        -webkit-mask:
            linear-gradient(#fff 0 0) content-box,
            linear-gradient(#fff 0 0);
        mask:
            linear-gradient(#fff 0 0) content-box,
            linear-gradient(#fff 0 0);
        -webkit-mask-composite: xor;
        mask-composite: exclude;
    }
}


@mixin gradient-border($border-width: 1px, $border-image: linear-gradient(90deg, #E85EFF 0%, #2DD9FF 100%)) {
    position: relative;
    // min-height: 200px;
    background: transparent;
    z-index: 1;

    &::before {
        position: absolute;
        content: "";
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: 3;
        pointer-events: none;
        // transition: opacity 0.3s linear;

        border: $border-width solid;
        border-image: $border-image;
        border-image-slice: 1;
        box-shadow: 4px 4px 15px rgb(0 0 0 / 25%);
        opacity: 1;
    }

    // &:hover::before {
    //     opacity: 1;
    // }
}

@mixin gradient-text-hover {
    background: linear-gradient(88.63deg, #FF0000 9.84%, #FF5C00 21.56%, #FFF800 31.54%, #00FF0A 45.86%, #00E0FF 58.01%, #AD00FF 71.03%, #FF0099 87.09%);
    background-size: 100% 100%;
    animation: rainbow 1s ease-in;
    background-clip: text;
    -webkit-background-clip: text;
    color: rgba($color, 1);
    text-align: center;
    transition: color .5s ease-in;

    &:hover,
    &.hover {
        color: rgba($color, 0);
    }
}

@mixin gradient-text-icon-hover($img, $imgHover, $color: $color) {
    .gradient-text {
        background: linear-gradient(88.63deg, #FF0000 9.84%, #FF5C00 21.56%, #FFF800 31.54%, #00FF0A 45.86%, #00E0FF 58.01%, #AD00FF 71.03%, #FF0099 87.09%);
        background-size: 100% 100%;
        animation: rainbow 1s ease-in;
        background-clip: text;
        -webkit-background-clip: text;
        color: rgba($color, 1);
        text-align: center;
        transition: color .5s ease-in;
    }

    .gradient-icon {
        width: 26px;
        height: 26px;
        background-image: $img;
        background-position: center;
        background-repeat: no-repeat;
        background-size: contain;

        -webkit-transition: background-image 0.3s ease-in-out;
        transition: background-image .5s ease-in-out;
    }

    &:hover {
        .gradient-text {
            color: rgba($color, 0);
        }

        .gradient-icon {
            background-image: $imgHover;
        }
    }
}

@keyframes rainbow {
    0% {
        background-position: 0%
    }

    100% {
        background-position: 200%
    }
}

@mixin font-custom ($font-family: null, $font-size: null, $font-weight: null, $font-style: null) {
    @if ($font-family) {
        font-family: $font-family !important;
    }

    @if ($font-size) {
        font-size: $font-size !important;
    }

    @if ($font-weight) {
        font-weight: $font-weight !important;
    }

    @if ($font-style) {
        font-style: $font-style !important;
    }
}