$color: #FFF;
$levias-color: #231F20;
$color-dim: #C4C4C4B2;
$bg-color: #FFFFFF;
$bg-color-2: #EDF1FB;
$bg-li-color: #6F7793;
$bg-card-color: #32304E;
$disable-color: #999999;
$btn-color: #96E5FF;
$btn-color-2: #61AD89;
$btn-color-3: #DB5E5F;
$btn-color-4: #6EB790;
$btn-color-5: #ff5b13;
$btn-color-cancel: #BF96FF;
$btn-disable: #D9D9D9;
$btn-edit: #ED7004;
$border-color: #ced4da;
$default-color: #D9D9D980;
$icon-color: #00FF3B;
$bg-popover-color: #3A3B41;
$tab-color: #96E5FF;
$tag-color: #F8A779;
$bookmark-color: #FFE600;
$placeholder-color: #FFFFFF;
$text-danger-color: #DB5E5F;
$badge-color: #FF0000;
$search-bg-color: #19162E;
$levica-green: #44A59E;
$bg-trasparent-color: #1d1731;
$purple:#BF96FF;

//anikana TY
$title-gradient-color: linear-gradient(90deg, rgb(232, 94, 255) 0%, rgb(45, 217, 255) 100%);
$gradient-color: linear-gradient(268.5deg, #AB79F8 0%, #5F49B5 99.48%);
$hover-gradient-color: linear-gradient(270deg, #E85EFF 0%, #2DD9FF 100%);
$hover-see-details: linear-gradient(270deg, #AB79F8 0%, #5F49B5 100%);
$badge-bg-color: #BF96FF;
$gradient-background: linear-gradient(192.05deg, #4B225C 0%, #19162D 23.44%, #19162D 72.92%, #153059 100%);
$levias-background: #FFF;
$btn-add-color: linear-gradient(rgba(255, 255, 255, 0), rgba(255, 255, 255, 0)), linear-gradient(90deg, #797783, #797783);

//absorb
$score-down-color: #FE4F4F;
$score-up-color: #52FF00;
