@import "color.scss";
@import "breakpoint.scss";
@import "mixin.scss";
@import "common.scss";

.game-detail-feature{
    justify-content: space-evenly;
    overflow: scroll;
    flex-wrap: nowrap;
    width: 100%;
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
}

.game-detail-feature::-webkit-scrollbar {
    display: none;
  }

.w-90{
    width: 90%;
}

.category-label{

}
.gamemaker_top{
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 100%;
    overflow: hidden;
    text-align: center;
}
.game-detail-preview-box-title {
    display: flex;
    flex-flow: row;
    width: 100%;
    overflow-x: scroll;
    flex-wrap: nowrap;
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */

    .custom-col-4 {
        flex: 0 0 auto;
        min-width: 33.333%;
        padding: 0 10px;
    }

    @include media-breakpoint-between('sm', 'lg') {
        .custom-col-4 {
            min-width: 19%;
        }
    }

    @include media-breakpoint-up('lg') {
        left: 0;
        .custom-col-4 {
            min-width: 18%;
        }
    }
}

.game-detail-preview-box{
    display: flex;
    flex-flow: row;
    overflow-x: scroll;
    flex-wrap: nowrap;
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
}

.game-detail-button{
    border: 1px solid black;
    border-radius: 20px;
}

.game-detail-preview-box::-webkit-scrollbar {
    display: none;
  }

.category-box{
    align-items: center;
}

.category-icon{
    width: 40px;
    height: 40px;
    background-color: gray;
    border-radius: 50%;
}

.category-name{

}

.game-start-banner{
    width: 100%;
    justify-content: space-evenly;
    bottom: 60px;
    border-top: 1px solid #000;
    background-color: #FFF;
}

p{
    word-wrap: break-word;
}

.productBox{
    padding-top:8% !important;

    padding: 12% 0% 3% 0%;
}
.product_title{
    font-size: 30px;
    text-align: center;
}
.product_row{
    padding : 7% 0px 12px 0px;
    /* display: flex;
    z-index: 1;
    flex-direction: row;
    justify-content: space-between; */
}
/* .card{
    
    margin-bottom: 10%;
} */
.sideCard{
    text-decoration: none !important;
    color: #000;
}
.sideCard:hover, .middleCard:hover{
    text-decoration: none !important;
    color: #000;
}
.middleCard{
    text-decoration: none !important;
    color: #000;
    margin: 0% 5%;
}
.productImgCard{
    height:350px;   
}
.productTitle{
    font-size: 20px;
}
.productText{
    font-size: 16px;
}
.loadMoreBtnBox{
    text-align: center;
    margin: 2% auto;
}
.loadMoreBtn{
    border: none;
    color: #E1593D;
    background: transparent;
    font-size: 24px;
}
/* Phone greater 300px */
@media (min-width : 370px ) and (max-width: 380px){
    .productBox{
        padding: 30% 0% 3% 0%;
    }
    .product_row{
        flex-direction: column;
    }
    .sideCard,
    .middleCard{
        margin: 0% 5%;
    }
}
/* Phone greater 400px */
@media (min-width : 381px ) and (max-width: 480px){
    .productBox{
        padding: 30% 0% 3% 0%;
    }
    .product_row{
        flex-direction: column;
    }
    .sideCard,
    .middleCard{
        margin: 0% 5%;
    }
}
/* Ipad (my_iPad)*/
@media (min-width : 481px ) and (max-width: 1000px){
    .productImgCard{
        height:220px;   
    }
}
/* Ipad pro */
@media (min-width : 1001px ) and (max-width: 1250px){
    .productImgCard{
        height:220px;   
    }
}
/* Laptop   */
@media (min-width : 1251px ) and (max-width: 1300px){}
/* Laptop over inches13-2  */
@media (min-width: 1301px) and (max-width: 1400px) {}
