@import "./app/color.scss";
@import "./app/breakpoint.scss";

.productBox{
    padding-top:8% !important;

    padding: 12% 0% 3% 0%;
}
.product_title{
    font-size: 30px;
    text-align: center;
}
.product_row{
    padding : 7% 0px 12px 0px;
    min-height: 85vh;
}

.product_search{
    border-top: 1px solid;
    border-bottom: 1px solid;
    display: flex;
}
// .card{
    
//     margin-bottom: 10%;
// }
.sideCard{
    text-decoration: none !important;
    color: #000;
}
.sideCard:hover, .middleCard:hover{
    text-decoration: none !important;
    color: #000;
}
.middleCard{
    text-decoration: none !important;
    color: #000;
    margin: 0% 5%;
}
.productImgCard{
    height:350px;   
}
.productTitle{
    font-size: 20px;
}
.productText{
    font-size: 16px;
}
.loadMoreBtnBox{
    text-align: center;
    margin: 2% auto;
}
.loadMoreBtn{
    border: none;
    color: #E1593D;
    background: transparent;
    font-size: 24px;
}

.news-image {
    height: 240px;
    background-color: linear-gradient(0.46deg, rgba(0, 0, 0, 0.69) 0.43%, rgba(0, 0, 0, 0.442708) 31.95%, rgba(0, 0, 0, 0) 99.64%);
    background-image: url(../assets/images/Anicana_FirstView_noOverlay_frame.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}

.mw-400 {
    max-width: 400px !important;
}

.sp-new-list {
    .p-title {
        font-size: 16px;
    }
}

.news-box-list {
    height: 50px;
    .news-category {
        font-family: Kardust;
        font-style: normal;
        font-weight: 400;
        font-size: 15px;
        line-height: 16px;
        height: 40px;
        position: relative;
        padding: 12px;
        text-align: center;
        margin-left: 15px;
        border-radius: 7px;
        mix-blend-mode: normal;
        border: none;
        box-sizing: border-box;
        cursor: pointer;

        &:not(.active) {
            background: linear-gradient(90deg, #E85EFF 0%, #2DD9FF 100%);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
        }
        
        &::before {
            content: "";
            position: absolute;
            inset: 0;
            padding: 1.5px;
            border-radius: 7px;
            background: $gradient-color !important;
            -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
            mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
            -webkit-mask-composite: xor;
            mask-composite: exclude;
        }
    }   
    
    .active {
        background: $gradient-color;
        color: #381E4A;
        border-radius: 7px;
    }
}

.rating {
    .rating-number {
        font-size: 50px;
        line-height: 64px;
    }
    
    .total-rating-text {
        font-size: 15px;
    }

    .progress-star-div {
        padding: 0 0 5px 0;
        .star {
            width: 50px;
            justify-content: flex-end;
            padding-right: 4px;

            svg {
                width: 7px;
                height: 7px;
                margin-right: 2px;
            }
        }

        .progress-div {
            width: 150px; 
            height: 4px;
            border-radius: 5px; 
            background: #7E7D89;
        }
    }
}

.review {
    .empty-star {
        margin-right: 10px;
        fill: none !important;
        stroke: white;
    }

    .gradient-star {
        margin-right: 10px;
    }

    .review-star {
        width: 11px;
        height: 11px;
        margin-right: 2px;
    }

    .review-box {
        background-color: #32304E;
        border-radius: 10px;
        padding: 10px 15px;
    }
}

/* Phone greater 300px */
@media (min-width : 370px ) and (max-width: 380px){
    .productBox{
        padding: 30% 0% 3% 0%;
    }
    .product_row{
        flex-direction: column;
    }
    .sideCard,
    .middleCard{
        margin: 0% 5%;
    }
}
/* Phone greater 400px */
@media (min-width : 381px ) and (max-width: 480px){
    .productBox{
        padding: 30% 0% 3% 0%;
    }
    .product_row{
        flex-direction: column;
    }
    .sideCard,
    .middleCard{
        margin: 0% 5%;
    }
}
/* Ipad (my_iPad)*/
@media (min-width : 481px ) and (max-width: 1000px){
    .productImgCard{
        height:220px;   
    }
}
/* Ipad pro */
@media (min-width : 1001px ) and (max-width: 1250px){
    .productImgCard{
        height:220px;   
    }
}
/* Laptop   */
@media (min-width : 1251px ) and (max-width: 1300px){}
/* Laptop over inches13-2  */
@media (min-width: 1301px) and (max-width: 1400px) {}