@import "color.scss";
@import "mixin.scss";
@import "breakpoint.scss";

.clip-left-bg-white {
  .arcanagen, span, div, input, .wallet-address-title {
    font-family: 'Cambay' !important;
  }
  .arcana-image-card-main {
    background: url('../../assets/images/glass-green-bg.png'), linear-gradient(180deg, rgba(34, 135, 74, 0.8) -442.31%, rgba(34, 135, 74, 0.8) 214.62%);

  }

  .owner-icon {
    &::before {
      background: transparent !important;
      border: 1px solid white !important;
    }
    path {
      fill: white;
    }
  }

  .arcana-gen-form .attribute-value, h4, input, .custom-label, a {
    color: #595959 !important;
  }

  .attribute-value.input-desc-text {
    color: #22874A !important;
  }

  .arcana-image-card-info {
    color: #fff !important;
    .attribute-value {
      color: #fff !important;
    }
  }

  .border-horizontal {
    border-left: 1px solid #fff !important;
    border-right: 1px solid #fff !important;
  }

  .divider {
    border-image-source: linear-gradient(270deg, rgba(232, 94, 255, 0) 0%, #22874A 31.77%, #22874A 66.15%, rgba(183, 121, 248, 0) 100%) !important;
  }

  .auth-form-input-absolute {
    border: 1px solid #595959 !important;
    border-radius: 0 !important; 
    box-shadow: none !important;
    font-family: 'Cambay' !important;

    &:hover, &:focus {
      border: 1px solid #22874A !important;
    }
  }

  .inactive-icon, .inactive-icon  path {
    fill: #595959;
  }

  .stroke-inactive-icon, .stroke-inactive-icon path {
    stroke: #595959 !important;
  }

  .border-box {
    border: 1px solid #22874A !important;

    &::before {
      background: transparent !important;
    }
  }

  .btn-custom-active, .btn-custom-active:disabled {
    background: #22874A !important;
    border-radius: 0!important;
    font-family: 'Cambay' !important;
  }
}

.clip-left-bg-blue {
  .arcanagen, span, div, input, .wallet-address-title {
    font-family: 'Helvetica' !important;
  }
  .arcana-image-card-main {
    background: url('../../assets/images/glass-white-bg.png');
  }

  .owner-icon {
    &::before {
      background: transparent !important;
      border: 1px solid white !important;
    }
    path {
      fill: white;
    }
  }

  .arcana-gen-form .attribute-value, h4, .custom-label {
    color: #fff !important;
  }

  a {
    color: #19162d !important;
  }

  .attribute-title {
    font-size: 16px !important;
  }

  
  .border-color {
    border-color: #00A8FF !important;
  }

  .attribute-value.input-desc-text {
    color: #00A8FF !important;
  }

  .arcana-image-card-info {
    color: #fff !important;
    .attribute-value {
      color: #fff !important;
    }
  }

  .border-horizontal {
    border-left: 1px solid #fff !important;
    border-right: 1px solid #fff !important;
  }

  .divider {
    border-image-source: linear-gradient(270deg, rgba(232, 94, 255, 0) 0%, #00A8FF 31.77%, #00A8FF 66.15%, rgba(183, 121, 248, 0) 100%) !important;
  }

  .auth-form-input-absolute {
    border: 1px solid #fff;
    background: #fff !important;
    border-radius: 0 !important; 
    box-shadow: none !important;
    font-family: 'Cambay' !important;
    color: #19162d !important;
    
    // &::before {
    //   @include text-gradient($gradient-background);
    // }

    &:hover, &:focus {
      // background: transparent !important;
      border: 1px solid #00A8FF !important;
    }
  }

  .inactive-icon, .inactive-icon  path {
    fill: #01072C;
  }

  .stroke-inactive-icon, .stroke-inactive-icon path {
    stroke: #01072C !important;
  }

  .border-box {
    // border: 1px solid #00A8FF !important;
    background: #fff !important;

    &::before {
      background: transparent !important;
    }
  }

  .btn-custom-active, .btn-custom-active:disabled {
    background: #00A8FF !important;
    border-radius: 0!important;
    span {
      @include text-gradient($gradient-background);
    }
  }
}