$resbreakpoints: (
    xs: 0,
    xsm: 360px,
    xsm2: 375px,
    xsm3: 468px,
    sm: 576px,
    md: 768px,
    ll: 820px,
    lg: 992px,
    xl: 1200px,
    xxl: 1400px) !default;

$spacingbreakpoints: (
    sm: 576px,
    md: 768px,
    lg: 992px,
    xl: 1200px,
    xxl: 1400px) !default;

$customCol: (
    2: 16.66666667,
    3: 25,
    4: 33.33333333,
    6: 50,
    20: 20
);

$positions: (
    x:x,
    y:y,
    t:t,
    b:b,
    l:l,
    r:r,
    around: around
);

@mixin media-breakpoint-up($breakpoint) {

    // If the key exists in the map
    @if map-has-key($resbreakpoints, $breakpoint) {

        // Prints a media query based on the value
        @media (min-width: map-get($resbreakpoints, $breakpoint)) {
            @content;
        }
    }

    // If the key doesn't exist in the map
    @else {
        @warn "Unfortunately, no value could be retrieved from `#{$breakpoint}`. "
        +"Available breakpoints are: #{map-keys($resbreakpoints)}.";
    }
}

@mixin media-breakpoint-down($breakpoint) {

    // If the key exists in the map
    @if map-has-key($resbreakpoints, $breakpoint) {

        // Prints a media query based on the value
        @media (max-width: map-get($resbreakpoints, $breakpoint)) {
            @content;
        }
    }

    // If the key doesn't exist in the map
    @else {
        @warn "Unfortunately, no value could be retrieved from `#{$breakpoint}`. "
        +"Available breakpoints are: #{map-keys($resbreakpoints)}.";
    }
}

@mixin media-breakpoint-between($breakpoint1, $breakpoint2) {

    // If the key exists in the map
    @if map-has-key($resbreakpoints, $breakpoint1) {
        @if map-has-key($resbreakpoints, $breakpoint2 ) {

            // Prints a media query based on the value
            @media (min-width: map-get($resbreakpoints, $breakpoint1)) and (max-width: map-get($resbreakpoints, $breakpoint2)) {
                @content;
            }
        }
    }

    // If the key doesn't exist in the map
    @else {
        @warn "Unfortunately, no value could be retrieved from `#{$breakpoint1}`. "
        +"Available breakpoints are: #{map-keys($resbreakpoints)}.";
    }
}

@mixin media-breakpoint-custom-up($breakpoint) {
    @media (min-width: $breakpoint) {
        @content;
    }
}

@mixin media-breakpoint-custom-down($breakpoint) {
    @media (max-width: $breakpoint) {
        @content;
    }
}

@mixin media-breakpoint-custom-between($breakpoint1, $breakpoint2) {
    @media (min-width: $breakpoint1) and (max-width: $breakpoint2) {
        @content;
    }
}