@import "color.scss";
@import "breakpoint.scss";
@import "mixin.scss";
@import "common.scss";

.selectbox {
    .selectbox-option {
        border: 1px solid transparent;
        border-radius: 10px;
        padding: 15px;
        width: 100%;
        min-width: 250px;
        margin-bottom: 16px;
        @include flex-center;

        &.selected {
            border: 1px solid $purple;
        }
    }
}