.recommandation-icon{
    width: 25px;
    height: 25px;
    background-color: #808080;
    border-radius: 50%;
}
.productBox{
    padding-top:8% !important;

    padding: 12% 0% 3% 0%;
}

.news-box{
    min-height: 70vh;
}

.product_title{
    font-size: 30px;
    text-align: center;
}
.product_row{
    // padding : 7% 0px 12px 0px;
    /* display: flex;
    z-index: 1;
    flex-direction: row;
    justify-content: space-between; */
}
// .card{
    
//     margin-bottom: 10%;
// }
.sideCard{
    text-decoration: none !important;
    color: #000;
}
.sideCard:hover, .middleCard:hover{
    text-decoration: none !important;
    color: #000;
}
.middleCard{
    text-decoration: none !important;
    color: #000;
    margin: 0% 5%;
}
.productImgCard{
    height:350px;   
}
.productTitle{
    font-size: 20px;
}
.productText{
    font-size: 16px;
}
.loadMoreBtnBox{
    text-align: center;
    margin: 2% auto;
}
.loadMoreBtn{
    border: none;
    color: #E1593D;
    background: transparent;
    font-size: 24px;
}

.feedback-wrapper.modal-box-bordered {
    width: 240px;
    height: 245px;
    max-width: 80%;
    max-height: 85%;
    display: flex !important;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding: 45px 10px !important;

    .swal2-html-container, .swal2-title {
        padding: 0;
        margin: 0;
        color: #fff !important;
    }

    .swal2-icon.swal2-icon-show {
        margin: 0;
        .feedback-star-icon {
            background-image: url('../assets/icons/star_gradient.svg');
            background-position: center;
            background-size: contain;
            background-repeat: no-repeat;
            width: 88px;
            height: 88px;
        }
    }

    .swal2-html-container {
        overflow: visible;
    }
}

/* Phone greater 300px */
@media (min-width : 370px ) and (max-width: 380px){
    .productBox{
        padding: 30% 0% 3% 0%;
    }
    .product_row{
        flex-direction: column;
    }
    .sideCard,
    .middleCard{
        margin: 0% 5%;
    }
}
/* Phone greater 400px */
@media (min-width : 381px ) and (max-width: 480px){
    .productBox{
        padding: 30% 0% 3% 0%;
    }
    .product_row{
        flex-direction: column;
    }
    .sideCard,
    .middleCard{
        margin: 0% 5%;
    }
}
/* Ipad (my_iPad)*/
@media (min-width : 481px ) and (max-width: 1000px){
    .productImgCard{
        height:220px;   
    }
}
/* Ipad pro */
@media (min-width : 1001px ) and (max-width: 1250px){
    .productImgCard{
        height:220px;   
    }
}
/* Laptop   */
@media (min-width : 1251px ) and (max-width: 1300px){}
/* Laptop over inches13-2  */
@media (min-width: 1301px) and (max-width: 1400px) {}