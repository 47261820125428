@import "breakpoint.scss";
@import "mixin.scss";

.item-profile-mini-height {
    min-height: 25px !important;
}

.item-date-mini-height {
    margin-top: 10px !important;
    min-height: 30px !important;
}

.new-item-margin-buttom-without-maker {
    margin-top: 10px;
    min-height: 28px;
}

.item-fix-size {
    max-width: 220px !important;
    max-height: 260px !important;
}

.p-title {
    white-space: normal !important;
    text-overflow: ellipsis !important;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    line-height: 120%;
    -webkit-box-orient: vertical;
    overflow: hidden;
}

.arcana-card {
    max-width: 1243.2px !important;
    @include media-breakpoint-up('md') {
        max-height: 801.1px !important;
    }
}

.arcana-image-card-main {
    max-width: 391px !important;
    max-height: 557.1px !important;
}

.arcana-image-card-header {
    padding: 0px 5px;
    max-width: 356.8px !important;
    max-height: 77.6px !important;
}

.arcana-image-card-info {
    padding: 0px 5px;
    max-width: 380.8px !important;
    max-height: 54.5px !important;
}

.arcana-image-card-photo {
    padding: 0px 5px;
    max-width: 356.8px !important;
    height: 360px !important;
    @include flex-center;
}

.arcana-info-card {
    padding: 0px 5px;
    max-width: 391px !important;
    max-height: 204px !important;
}

.arcana-ask-bid-card {
    max-width: 494px !important;
    max-height: 156px !important;
}

.arcana-detail-card {
    max-width: 494px !important;
    // max-height: 226px !important;
}

.arcana-scores-card {
    max-width: 494px !important;
    max-height: 296.4px !important;
}

.arcana-description-card {
    //max-width: 286.8px !important;
    //max-height: 131.2px !important;
    @include media-breakpoint-down('sm') {

    }

    @media screen and (min-width: 992px) and (max-width: 1308px) {
        max-width: 796px !important;
    }
}

.item-type-name {
    mix-blend-mode: normal;
    // opacity: 0.5;
    // backdrop-filter: blur(10px);
    border-radius: 10px;
    box-sizing: border-box;
    
    font-size:10px !important;
    background: $gradient-color;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
  
    font-style: normal;
    line-height: 17px;
    @include border-box($gradient-color, 1.5px, 7px);
    padding-bottom: 5px;
    padding: 3px 10px 3px!important;
    @include media-breakpoint-down('sm') {
        height: 20px !important;
        font-size:7.5px !important;
        border-radius: 5px;
        padding: 1.5px 5px 1.5px!important;
    }
}

.item-publish-date {
    @include media-breakpoint-down('sm') {
        font-size:7.5px !important;
    }
}
