@import 'color.scss';
@import 'common.scss';
@import 'breakpoint.scss';

.wrapper {
    ul {
        li {

            a,
            p {
                margin-left: -15px;
            }
        }
    }
}

.sidebar-brand {
    height: 40px !important;
}

#wrapper.toggled #page-content-wrapper {
    margin-right: -300px !important;
}

#wrapper.toggled #sidebar-wrapper {
    width: 300px !important;
}

#wrapper.toggled {
    padding-left: 300px !important;
}

.sidebar-nav {
    width: 250px !important;

    &.filter-nav {
        width: 350px !important;
    }
}

.sidebar-fullscreen {
    // display: none;
    width: 100vw !important;
    height: 100vh !important;
    position: fixed !important;
    top: 0 !important;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 1021;

    &.sidebar-nav {
        display: none !important;

        &.show {
            display: block !important;
        }
    }

}

.accor-btn {
    &:not(.collapsed) {
        .accor-icon {
            transform: rotate(90deg);
            transition: transform .3s ease-in-out;
            fill: #bf96ff !important;
        }
    }

    &.collapsed {
        .accor-icon {
            transform: rotate(0deg);
            transition: transform .3s ease-in-out;
            fill: #bf96ff !important;
        }
    }
}

#sidebar-wrapper {
    left: 300px !important;
    margin-left: -300px !important;
    padding-bottom: 80px;
}

.side-content {
    border-radius: 5px;
    // min-height: $sidebarHeight;
    margin-bottom: 0;
    width: auto;
    min-width: 0;
    flex: 1;

    @include media-breakpoint-down('lg') {
        padding: 0;
    }

    &.bg2 {
        background-color: $bg-color-2;
        padding: 10px;
    }

    &-body {
        // min-height: calc($heightWithoutNav - $subNavHeight);
        // min-height: $heightWithoutNav;

        // width: 100%;
        // position: relative;

        @include media-breakpoint-up('lg') {
            // height: calc($heightWithoutNav - $subNavHeight);
            height: calc($heightWithoutNav);
            overflow: hidden;

            .toggle-signout {
                display: none !important;
            }

            .side-content-scroll {
                width: 100%;
                position: relative;
                height: calc($heightWithoutNav);
                overflow-y: scroll;

                .col {
                    //padding-left: 0 !important;
                }
            }

            &.hasSubNav {
                .side-content-scroll {
                    height: calc($heightWithoutNav);
                }
            }
        }

        @include media-breakpoint-down('lg') {
            .toggle-signout-sm {
                display: none !important;
            }
        }

        .side-content-scroll {
            >div {
                padding-bottom: 230px !important;

                @include media-breakpoint-down('lg') {
                    padding-bottom: 135px !important;

                    &.hasAdditionalBottom {
                        padding-bottom: 185px !important;

                    }
                }
            }
        }

        &::before {
            content: "";
            display: block;
            position: fixed;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            opacity: 0.8;
            // background-image: url(../../assets/images/background.webp);
            background-size: cover;
            background-position-x: 140px;
            background-position-y: 111px;
            background-attachment: fixed;
            background-repeat: no-repeat;

            @include media-breakpoint-down('lg') {
                background-position: center;
            }
        }
    }
}

@media(min-width:768px) {
    #wrapper {
        padding-left: 0px !important;
    }

    #sidebar-wrapper {
        width: 300px !important;
    }
}

.side-content-wrapper {
    min-height: 70vh;
}

.form-wrapper {
    width: 100%;
    max-width: 450px;
}

.btn {
    max-width: 450px !important;

    &.max-w-unset {
        max-width: unset !important;
    }
    // font-size: 30px !important;
}


.sidebarmenu-5 {
    margin-bottom: 70px !important;
    // font-size: 30px !important;
}

// .isChrome {
//     .side-content-scroll {
//         >div {
//             @include media-breakpoint-down('lg') {
//                 padding-bottom: 230px !important;
//                 &.hasAdditionalBottom {
//                     padding-bottom: 300px !important;
//                 }
//             }
//         }
//     }
// }