.productBox{
    padding-top:8% !important;

    padding: 12% 0% 3% 0%;
}
.product_title{
    font-size: 30px;
    text-align: center;
}
.product_row{
    padding : 7% 0px 12px 0px;
    /* display: flex;
    z-index: 1;
    flex-direction: row;
    justify-content: space-between; */
}
/* .card{
    
    margin-bottom: 10%;
} */
.sideCard{
    text-decoration: none !important;
    color: #000;
}
.sideCard:hover, .middleCard:hover{
    text-decoration: none !important;
    color: #000;
}
.middleCard{
    text-decoration: none !important;
    color: #000;
    margin: 0% 5%;
}
.productImgCard{
    height:350px;   
}
.productTitle{
    font-size: 20px;
}
.productText{
    font-size: 16px;
}
.loadMoreBtnBox{
    text-align: center;
    margin: 2% auto;
}
.loadMoreBtn{
    border: none;
    color: #E1593D;
    background: transparent;
    font-size: 24px;
}

.newsdetail-category{
    font-family: Kardust !important;
    font-size:18px !important;

}
.newsdetail-datetime{
    font-family: Kardust !important;
    font-size: 18px !important;
    text-align: right;
}

@media (max-width: 480px){
    .newsdetail-category{
        font-family: Kardust !important;
        width: 40%;
        font-size:18px !important;

    }

    .newsdetail-datetime{
        font-family: Kardust !important;
        font-size:18px !important;
        text-align: right;
    }

}

/* Phone greater 300px */
@media (min-width : 370px ) and (max-width: 380px){
    .productBox{
        padding: 30% 0% 3% 0%;
    }
    .product_row{
        flex-direction: column;
    }
    .sideCard,
    .middleCard{
        margin: 0% 5%;
    }
}
/* Phone greater 400px */
@media (min-width : 381px ) and (max-width: 480px){
    .productBox{
        padding: 30% 0% 3% 0%;
    }
    .product_row{
        flex-direction: column;
    }
    .sideCard,
    .middleCard{
        margin: 0% 5%;
    }
}
/* Ipad (my_iPad)*/
@media (min-width : 481px ) and (max-width: 1000px){
    .productImgCard{
        height:220px;   
    }
}
/* Ipad pro */
@media (min-width : 1001px ) and (max-width: 1250px){
    .productImgCard{
        height:220px;   
    }
}
/* Laptop   */
@media (min-width : 1251px ) and (max-width: 1300px){}
/* Laptop over inches13-2  */
@media (min-width: 1301px) and (max-width: 1400px) {}
