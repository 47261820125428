.react-pdf__Page__textContent {
  display: none !important;
}

.react-pdf__Page__annotations {
  display: none !important;
}

.react-pdf__Page__canvas {
  width: 100% !important;
  height: 100% !important;
}
.bg-grey {
  background-color: rgb(82, 86, 89) !important;
  &::before {
    background-image: unset !important;
  }
}
.mainbody-pdf {
  background-color: rgb(82, 86, 89) !important;
  padding: 0 !important;
  border-radius: 0 !important;
}
.levias-pc-block-pdf {
  background: none !important;
  border-radius: 0 !important;
}