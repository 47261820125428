/* .App {
  background: linear-gradient(192.05deg, #4B225C 0%, #19162D 23.44%, #19162D 72.92%, #153059 100%);
  mix-blend-mode: normal;
  color: #fff;
} */

/* .App::before {
  content: "";
  display: block;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(192.05deg, #4B225C 0%, #19162D 23.44%, #19162D 72.92%, #153059 100%);
  mix-blend-mode: normal;
} */

.list {
  text-align: left;
  max-width: 750px;
  margin: auto;
}

.submit-form {
  max-width: 300px;
  margin: auto;
}

.edit-form {
  max-width: 300px;
  margin: auto;
}

a {
  text-decoration: none;
  color: #fff;
}

a:hover {
  text-decoration: none;
  color: #000;
}

body {
  overflow-y: scroll;
  /* Add the ability to scroll */
}

/* Hide scrollbar for Chrome, Safari and Opera */
body::-webkit-scrollbar {
  display: none;
}

.ladda-button[data-style=expand-right] .ladda-spinner {
  right: -6px;
}
.ladda-label{
  margin: auto;
}
.ladda-button[data-style=expand-right][data-size="s"] 
.ladda-spinner,.ladda-button[data-style=expand-right][data-size="xs"] 
.ladda-spinner{right:-4px}.ladda-button[data-style=expand-right][data-loading]{padding-right:56px}
.ladda-button[data-style=expand-right][data-loading] .ladda-spinner{opacity:1}
.ladda-button[data-style=expand-right][data-loading][data-size="s"],
.ladda-button[data-style=expand-right][data-loading][data-size="xs"]{padding-right:40px}