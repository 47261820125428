.chain {
    z-index: 3;
    position: fixed;
    top: -10px;
    left: -10px;
    width: 160px;
}

.title {
    color: #BF96FF;
    font-weight: 600;
    font-size: 35px;
    font-family: Kardust;
    text-align: center;
    margin-bottom: 30px;
}

.card__wrapper {
    position: relative;
    width: 100%;
    max-width: 370px;
    margin-bottom: 15px;
    cursor: pointer;
}

.card {
    width: calc(100% - 15px);
    max-width: 355px;
    height: 219px;
    border-radius: 10px;
    background-color: #32304E;

    display: flex;
    flex-wrap: nowrap;
}

.card__image {
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    width: 170px;
    height: 219px;
}

.card__title {
    font-weight: 400;
    font-size: 12px;
    margin: 0;
}

.card__body,
.card__body>* {
    font-weight: 500;
    font-size: 10px !important;
    text-align: justify;
}

.persona__title {
    font-size: 14px !important;
    font-weight: 400;
    color: #BF96FF;
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    gap: 5px;
    margin-top: auto;
}

.persona__description {
    font-size: 14px;
    font-weight: 500;
}

.persona__icon {
    width: 15px;
    height: 12px;
}

.persona__image {
    flex: 0 0 76px;
    width: 76px;
    height: 102px;
    border-radius: 8px;
}

.float {
    position: absolute;
    bottom: -15px;
    right: 0;
}

.border {
    border: 1px solid #BF96FF;
}

.img {
    object-fit: cover;
    object-position: center;
}

.date {
    color: #BF96FF;
    font-weight: 500;
    font-size: 10px;
    margin: 5px 0 10px 0;
}

.drawchainLayout {
    display: grid;
    grid-template: auto / 1fr;
    gap: 10px;
}

@media screen and (min-width:991px) {
    .drawchainLayout {
        display: grid;
        grid-template: auto / repeat(2, 1fr);
    }
}