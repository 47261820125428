@import './app/icon.scss';
@import './app/color.scss';
@import './app/breakpoint.scss';

.bottom-card {
    bottom: 36px;
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
    background-color: transparent !important;
    margin-bottom: 0 !important;
    padding: 0 16px;
    border: none;

    @include media-breakpoint-up('lg') {
        max-width: 400px;
        position: relative;
        top: -95px;
        left: 40px;
    }

    .card-body {
        padding: 0;
        // background: $bg-card-color;
        border-radius: 10px;
    }

    .btn-custom-active {
        &:disabled {
            opacity: 1;
            background: rgb(130 128 140 / 65%) !important;
            color: rgb(255 255 255 / 55%) !important;
        }
    }
}

.bg-transparent {
    backdrop-filter: blur(10px);
}

.bottom-nav {
    width: 100%;
    height: 65px;
    z-index: 999;
    justify-content: space-evenly;
    align-items: center;
    // opacity: 0.9;
    // background: url(../assets/images/Rectangle.png), linear-gradient(180deg, rgba(255, 255, 255, 0.105) -442.31%, rgba(255, 255, 255, 0) 214.62%);
    backdrop-filter: blur(25px);
    background: rgba(255, 255, 255, 0.17);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    text-align: center;
    border-radius: 15px;

    div {
        // display: flex;
        align-items: center;
        justify-content: center;
        line-height: 8px;
        color: rgba(255, 255, 255, .27) !important;
        margin-top: 2px;
    }

    .active {
        // color: #fff;
        background: $gradient-color;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-size: 100%;
        background-image: linear-gradient(90deg, rgb(232, 94, 255) 0%, rgb(45, 217, 255) 100%);
        //height: 10px;
        //max-height: 10px;
    }

    span {
        font-size: 8px !important;
        color: rgba(255, 255, 255, .27) !important;
        font-family: 'NotoSans';
        font-weight: bold;
    }
}

.isMobileSafari {
    .bottom-nav {

        div,
        span {
            color: rgba(255, 255, 255, .1) !important;
        }
    }
}

.product-inactive-icon {
    opacity: 0.27;
}

.square-inactive-icon {
    opacity: 0.27;

    path,
    rect {
        fill: white;
    }
}