.product_card_link{
    text-decoration: none;
    color : black;
}

.product_card{
    position: relative;
    margin: 10px auto;
    display: flex;
    flex-direction: column;
    border-bottom: 1px solid #000;
    z-index: 1;
    justify-content: center;  
}

.product_card::before {
    content: '';
    background: rgba(133, 119, 111, 0.6);
    position: absolute;
    left: 100%;
    right: 0;
    top: 0;
    bottom: 0;
    border-radius: 10px;
    transition: all ease-in-out 0.3s;
    z-index: 1;
}
/* 
.product_card:hover::before {
    left: 0;
    cursor: pointer;
} */
.product_card > img{
    height:100%;
    width: 100%;
    border-radius: 10px;
    
}

/* .product_card > img:hover{
    transition: all 0.4s ease;
} */

/* .product_card .product_card_info {
    position: absolute;
    left: 0;
    right: 0;
    bottom: -50px;
    z-index: 3;
    color: #fff;
    text-align: center;
    opacity: 0;
    transition: all ease-in-out 0.3s;
}

.product_card:hover .product_card_info {
    opacity: 1;
    bottom: 10px;
} */

.product_card_info > .title{
    font-size: 20px;
    margin-left:30px;
}

.product_card_info > .new{
    font-size: 25px;
    margin-left:30px;
}

.product_card_info > .body{
    font-size: 20px;
    margin-top:30px;
    margin-left:30px;
}


.product_card_info > .game_category_id{
    font-size: 20px;
    margin-top:30px;
    margin-left:30px;
}

.product_card_info  > .price{
    font-size: 16px;
}
.price_currency{
    display: flex;
    flex-direction: row;
    text-align: center;
    justify-content: center;
}

