@import "color.scss";
@import "breakpoint.scss";
@import "mixin.scss";
@import "common.scss";

.row-no{
    @include media-breakpoint-up('lg') {
        padding-right: 0px!important;
        max-width: 2%;
    }
    
    @include media-breakpoint-down('lg') {
        padding-right: 0px!important;
        max-width: 5%;
    }
}
.row-item{
    @include media-breakpoint-up('lg') {
        padding-right: 0px!important;
        max-width: 98%;
    }
    @include media-breakpoint-down('lg') {
        padding-right: 0px!important;
        max-width: 95%;
    }
}
.row-sub-no{
    @include media-breakpoint-up('lg') {
        padding-right: 0px!important;
        padding-left:22px!important;
        max-width: 5%;
    }
    @include media-breakpoint-down('lg') {
        padding-right: 0px!important;
        max-width: 10%;
    }
}
.row-sub-item{
    @include media-breakpoint-up('lg') {
        padding-right: 0px!important;
        padding-left: 0px!important;
        max-width: 93%;
    }
    @include media-breakpoint-down('lg') {
        padding-right: 0px!important;
        max-width: 85%;
    }
}