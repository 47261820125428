@import 'color.scss';
@import 'mixin.scss';
@import 'common.scss';
@import 'breakpoint.scss';

$max: 12;
$angle: 360/$max;
$size: 120px;
$fill: $badge-color;

.l-wrapper {
  width: $widthWithoutSidebar;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto 0 auto $sidebarWidth;
  text-align: center;
  @include flex-center;

  &.inline-loading {
    width: 100%;
    height: 100%;
    min-height: 200px;
    position: initial;
    margin: 0;
  }

  @include media-breakpoint-custom-down(991px){
    top:-40px;
    margin:auto;
  }

  svg {
    height: $size;
    width: $size;
    margin: 0 2em 2em;
    overflow: visible;
    /*   border: 1px solid red; */
  }

  .g-circles {
    //transform: scale(.9) translate(7px, 7px);
  }

  @function transform($item: 1) {
    $base: rotate(#{-$angle*$item}deg) translate(0px, 0px);
    @return $base;
  }

  .g--circle {
    transform-origin: $size/2 $size/2;

    fill: $fill;
    animation: opacity 1.2s linear infinite;

    @for $item from 1 through $max {
      &:nth-child(#{$max}n + #{$item}) {
        animation-delay: -#{$item/10}s;
        transform: transform($item) scale(0.9);
      }
    }
  }

  .g-circles--v1 .g--circle {
    fill-opacity: 0;
    animation-name: opacity;
  }

  .g-circles--v2 .g--circle {
    fill-opacity: 0;
    stroke-opacity: 0;
    stroke-width: 1;
    stroke: yellowgreen;
    animation-name: opacity-stroke, colors, colors-stroke;

    .u--circle {
      //transform: scale(.6) translate(10px,10px);
      //transform-origin: 10px 10px;
      animation: transform-2 1.2s linear infinite;
    }
  }

  .g-circles--v2 .g--circle {
    @for $item from 1 through $max {
      &:nth-child(#{$max}n + #{$item}) .u--circle {
        animation-delay: -#{$item/10}s;
      }
    }
  }

  .g-circles--v3 .g--circle {
    fill-opacity: 1;
    animation-name: colors;
  }

  .g-circles--v4 {
    .g--circle {
      fill-opacity: 1;
      fill: orange;
      animation-name: opacity, colors-3;
    }

    .u--circle {
      animation: transform 1.2s linear infinite;
    }
  }

  .g-circles--v4 .g--circle {
    @for $item from 1 through $max {
      &:nth-child(#{$max}n + #{$item}) .u--circle {
        animation-delay: -#{$item/10}s;
      }
    }
  }
}

@mixin setSize($size) {
  width: $size;
  height: $size;
}

@keyframes width-resize {

  10% {
    transform: scale(0.1);
  }

  20% {
    transform: scale(0.2);
  }

  30% {
    transform: scale(0.3);
  }

  40% {
    transform: scale(0.4);
  }

  50% {
    transform: scale(0.5);
  }

  60% {
    transform: scale(0.6);
  }

  70% {
    transform: scale(0.7);
  }

  80% {
    transform: scale(0.8);
  }

  90% {
    transform: scale(0.9);
  }

  100% {
    transform: scale(0.0);
  }
}

@keyframes opacity {
  3% {
    fill-opacity: 1;
  }

  75% {
    fill-opacity: 0;
  }

}

@keyframes opacity-stroke {
  10% {
    stroke-opacity: 1;
  }

  85% {
    stroke-opacity: 0;
  }

}

@keyframes colors {
  0% {
    fill: #FF7A00;
  }

  10% {
    fill: #FFD600;
  }

  20% {
    fill: #DBFF00;
  }

  30% {
    fill: #05FF00;
  }

  40% {
    fill: #00FFB2;
  }

  50% {
    fill: #00D1FFE5;
  }

  60% {
    fill: #0085FFCC;
  }

  70% {
    fill: #2400FFB2;
  }

  80% {
    fill: #DB00FF99;
  }

  90% {
    fill: #FF006B80;
  }
}

@keyframes colors-stroke {
  0% {
    stroke: yellowgreen;
  }

  10% {
    stroke: gold;
  }

  75% {
    stroke: crimson;
  }
}

@keyframes colors-2 {
  0% {
    fill: yellow;
  }

  50% {
    fill: red;
  }

  65% {
    fill: orangered;
  }

  95% {
    fill: gold;
  }
}

@keyframes colors-3 {
  0% {
    fill: yellowgreen;
  }

  50% {
    fill: turquoise;
  }

  65% {
    fill: yellow;
  }

  95% {
    fill: orange;
  }
}

@keyframes transform {
  10% {
    transform-origin: 70px 70px;
    transform: scale(.75);
  }
}

@keyframes transform-2 {
  40% {
    transform: scale(.85) translate(10px, 10px);
  }

  60% {
    stroke-width: 20;
  }
}

.spinner {
  animation: spin infinite 1s linear;
  margin: 0 10px;
  /*You can increase or decrease the timer (5s) to 
   increase or decrease the speed of the spinner*/
}

@keyframes spin {
from {
  transform: rotate(0deg);
}
to {
  transform: rotate(360deg);
}
}
