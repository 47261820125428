@font-face {
  font-family: Kardust;
  src: local('Kardust'), url(./assets/fonts/Kardust.otf) format('opentype');
}

@font-face {
  font-family: 'KardustExpanded';
  src: local('KardustExpanded'), url(./assets/fonts/KardustExpanded-Regular.otf) format('opentype');
}

@font-face {
  font-family: ' Expanded';
  src: local('Kardust Expanded'), url(./assets/fonts/KardustExpanded-Regular.otf) format('opentype');
}

@font-face {
  font-family: 'KardustCondensed';
  src: local('KardustCondensed'), url(./assets/fonts/KardustCondensed-Regular.otf) format('opentype');
}

@font-face{
  font-family: 'BD-Colonius';
  src: url(./assets/fonts/BD-Colonius.otf);
}

@font-face{
  font-family: 'Hiragino Sans';
  src: url(./assets/fonts/Hiragino Sans.otf);
}

@font-face{
  font-family: 'Monoglyceride';
  src: url(./assets/fonts/monog___.ttf);
  line-height: 36px !important;
}

@font-face{
  font-family: 'Monoglyceride';
  src: url(./assets/fonts/monog___.ttf);
  line-height: 36px !important;
}

@font-face {
  font-family: 'Kardust';
  src: local('Kardust'), url(./assets/fonts/Kardust.otf) format('opentype');
}

@font-face {
  font-family: 'KardustLight';
  src: local('KardustLight'), url(./assets/fonts/Kardust-Light.otf) format('opentype');
}

@font-face {
  font-family: 'KardustExpandedLight';
  src: local('KardustExpandedLight'), url(./assets/fonts/KardustExpanded-Light.otf) format('opentype');
}

@font-face {
  font-family: 'KardustCondensedLight';
  src: local('KardustCondensedLight'), url(./assets/fonts/KardustCondensed-Light.otf) format('opentype');
}

@font-face {
  font-family: 'KardustCondensedRegular';
  src: local('KardustCondensedRegular'), url(./assets/fonts/KardustCondensed-Regular.otf) format('opentype');
}

@font-face {
  font-family: 'Kardust Condensed';
  src: local('Kardust Condensed'), url(./assets/fonts/KardustCondensed-Regular.otf) format('opentype');
}


@font-face {
  font-family: 'KardustExpandedRegular';
  src: local('KardustExpandedRegular'), url(./assets/fonts/KardustExpanded-Regular.otf) format('opentype');
}

/* Noto Sans JP */
@font-face {
  font-family: 'NotoSans';
  src: local('NotoSans'), url(./assets/fonts/NotoSansJP-Regular.otf) format('opentype');
}

@font-face {
  font-family: 'NotoSansLight';
  src: local('NotoSansLight'), url(./assets/fonts/NotoSansJP-Light.otf) format('opentype');
}

@font-face {
  font-family: 'NotoSansBlack';
  src: local('NotoSansBlack'), url(./assets/fonts/NotoSansJP-Black.otf) format('opentype');
}

@font-face {
  font-family: 'NotoSansBold';
  src: local('NotoSansBold'), url(./assets/fonts/NotoSansJP-Bold.otf) format('opentype');
}

@font-face {
  font-family: 'NotoSansMedium';
  src: local('NotoSansMedium'), url(./assets/fonts/NotoSansJP-Medium.otf) format('opentype');
}

@font-face {
  font-family: 'NotoSansThin';
  src: local('NotoSansThin'), url(./assets/fonts/NotoSansJP-Thin.otf) format('opentype');
}

@font-face {
  font-family: 'CambayRegular';
  src: local('CambayRegular'), url(./assets/fonts/CambayRegular.ttf) format('opentype');
}

@font-face {
  font-family: 'Helvetica';
  src: local('Helvetica'), url(./assets/fonts/Helvetica.ttf) format('opentype');
}

/* body {
  margin: 0;
  font-family: KardustExpanded, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
} */

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
