.activeimg{
    border : 2px solid #E1593D;
    border-radius: 5px;
}
.product_image_row{
    padding: 8% 0% 1% 0%;
    width: 150%;
    display: flex;
    flex-direction: row;
}
.product_image_thumbnail{
    width: 13%;
    height : 600px;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.product_image_thumbnail ul li{
    display: flex;
    flex-direction: column;
    margin: 20% auto ;
    justify-content: center;
    align-items: center;
}
.smallImgOne{
    width: 86px;
    height: 70px;
    cursor: pointer;
    border-radius: 5px;
   box-shadow: 0 6px 20px rgba(56, 125, 255, 0.17);
    -webkit-filter: drop-shadow(0 6px 20px rgba(56, 125, 255, 0.017));
    filter: drop-shadow(0 6px 20px rgba(56, 125, 255, 0.017));
}
.product_image_thumbnail ul{
    list-style-type: none;
}

.largeImg, .magnifier-image{
    object-fit:fill;
    background-image: cover;
    width: 700px;
    height: 560px;
    border-radius: 5px;
}
.product_image_title_div{
    width: 40%;
    text-align: center;
    padding: 8% 0;
}
.productTitleText{
    font-size: 30px;
    margin-left:50%;
}

.productImage{
    margin-top:2%;
    margin-left:50%;
    margin-bottom:2%;

}

.productTitlePrice{
    font-size: 40px;
    color : #E1593D;
    margin-left:550px;

}
.productTitleBtn{
    font-size: 24px;
    width:40%;
    padding:2px;
    text-align:center;
    justify-content: center;
    border:1px solid #000000;
    background-color: transparent;
    border-radius:1rem;
    margin-left:50%;
}
.productTitleBtnText{
    font-size: 24px;
    margin-left:50%;
    padding-top:20px;
}
/* Product Text Box */
.productTextBox{
    width: 100%;
    padding: 0px;
    display: flex;
    flex-direction: row;
}
.firstSpacer{
    width: 13%;
}
.productTextParagraph{
    width: 54%;
    font-size: 18px;
}
.secondSpacer{
    width: 32%;
}

/* Making Process */
.MProcessTitle{
    font-size: 36px;
    padding: 2% 0%;
    text-align: center;

}
.MProcessBox{
    width: 100%;
    display: flex;
    flex-direction: row;
}
.MProcessSpacer{
    width: 10%;
}
.MProcessImgBox{
    width: 80%;
}
/* .MProcessImg{
    margin-top: 2%;
    justify-content: space-between;
} */

.process_image_container{
    display: grid;
    padding : 0px 2rem ; 
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    
}
.MProcessImgBoxTitle{
    font-size: 24px;
    margin-left : 2%;
}
.process_description{
    font-size: 18px;
    margin-left : 2%;
}
.MProcessCaption{
    font-size: 16px;
    width: 270px;
    text-align: center;
}
.MProcessArtistBox{
    width: 20%;
    /* background-color: beige; */
}
.MProcessArtistTitle{
    font-size: 16px;
    text-align: center;
    padding: 2% 0%;
}
.artistName{
    font-size: 16px;
    text-align: center;
}
.MProcessTwoArtist{
    display: flex;
    flex-direction: column;
    font-size: 18px;
    margin: 5px auto;
    align-items: center;
}
.artist{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.MProcessArtistImg{
    width:80px;
    height:80px;
    border-radius:50%;
    margin-bottom: 10px;
    background: #C4C4C4;
}

/* Similar Product */
.SProductTitle{
    padding: 3% 0%;
    text-align: center;
    font-size: 36px;
}
.SProductImgBox{
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
}
.SProductImgCaption{
    text-align: center;
    font-size: 24px;
    margin: 16px auto;
}
.similarImg{
    width: 100%;
}

/* Phone greater 300px */

@media (min-width : 370px ) and (max-width: 391px){
    .product_image_row{
        padding: 29% 0% 0% 0%;
        flex-direction: column;
    }
    .product_image_thumbnail{
        width: 100%;
        flex-direction: row;
        justify-content: space-between;
    }
    .product_image_thumbnail ul{
        list-style-type: none;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }
    .product_image_thumbnail ul li{
        margin: 2% auto 0% auto ;
        padding: 0% 4%;
    }
    .smallImgOne{
        width: 70px;
        height: 75px;
    }
    .product_image_cover{
        width: 100%;
    }
    .largeImg, .magnifier-image{
        min-width: 100%;
        width: 360px;
        height: 300px;
    }
    .product_image_title_div{
        width: 100%;
        padding: 14% 0%;
    }
    .productTextBox{
        width: 100%;
        padding: 0px 11px;
        flex-direction: row;
    }
    .firstSpacer{
        width: 0%;
    }
    .productTextParagraph{
        width: 100%;
        font-size: 20px;
    }
    .secondSpacer{
        width: 0%;
    }
    /* Making Process */
    .MProcessBox{
        flex-direction: column;
    }
    .MProcessSpacer{
        width: 0%;
    }
    .MProcessImgBox{
        width: 100%;
    }
    .MProcessImg{
        display: flex;
        justify-content: space-around;
    }
    .process_image{
        width : 200px !important;
        height : 200px !important;
    }
    .mProcessImgOne,
    .mProcessImgTwo{
        width: 156px;
        height: 134px;
    }
    .MProcessCaption {
        font-size: 14px;
        width: 150px;
        text-align: center;
    }
    .MProcessTitle {
        padding: 5% 0%;
        text-align: center;
    }
    .MProcessImgBoxTitle{
        padding: 0% 4%;
    }
    .MProcessArtistBox{
        width: 100%;
    }
    .MProcessTwoArtist{
        display: flex;
        flex-direction: row;
        margin: 5px auto;
        justify-content: space-evenly;
    }
    .SProductImgBox{
        flex-direction: column;
    }
    .SProductImgCaption{
        margin: 20px auto;
    }
}
/* Phone greater 400px */
@media (min-width : 392px ) and (max-width: 700px){
    .product_image_row{
        padding: 29% 0% 0% 0%;
        flex-direction: column;
    }
    .product_image_thumbnail{
        width: 100%;
        flex-direction: row;
        justify-content: space-between;
    }
    .product_image_thumbnail ul{
        list-style-type: none;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }
    .product_image_thumbnail ul li{
        margin: 2% auto 0% auto ;
        padding: 0% 5.5%;
    }
    .smallImgOne{
        width: 70px;
        height: 75px;
    }
    .product_image_cover{
        width: 100%;
    }
    .largeImg, .magnifier-image{
        min-width: 100%;
        width: 360px;
        height: 300px;
    }
    .product_image_title_div{
        width: 100%;
        padding: 14% 0%;
    }
    .productTextBox{
        width: 100%;
        padding: 0px 11px;
        flex-direction: row;
    }
    .firstSpacer{
        width: 0%;
    }
    .productTextParagraph{
        width: 100%;
        font-size: 20px;
    }
    .secondSpacer{
        width: 0%;
    }
     /* Making Process */
     .MProcessBox{
        flex-direction: column;
    }
    .MProcessSpacer{
        width: 0%;
    }
    .MProcessImgBox{
        width: 100%;
    }
    .MProcessImg{
        display: flex;
        justify-content: space-around;
    }
    .mProcessImgOne,
    .mProcessImgTwo{
        width: 156px;
        height: 134px;
    }
    .MProcessCaption {
        font-size: 14px;
        width: 150px;
        text-align: center;
    }
    .MProcessTitle {
        padding: 5% 0%;
        text-align: center;
    }
    .MProcessImgBoxTitle{
        padding: 0% 6%;
    }
    .MProcessArtistBox{
        width: 100%;
    }
    .MProcessTwoArtist{
        display: flex;
        flex-direction: row;
        margin: 5px auto;
        justify-content: space-evenly;
    }
    .SProductImgBox{
        flex-direction: column;
    }
    .SProductImgCaption{
        margin: 20px auto;
    }
}
/* Ipad pro */
@media (min-width : 701px ) and (max-width: 1250px){
    .product_image_row{
        padding: 17% 0% 3% 0%;
    }
    .product_image_thumbnail{
        width: 16%;
        display: flex;
        flex-direction: column;
    }
    .product_image_thumbnail ul{
        margin-bottom: 0px;
    }
    .product_image_thumbnail ul li{
        margin: 18% auto ;
    }
    .product_image_cover{
        width: 45%;
    }
    .smallImgOne{
        width: 90px;
        height: 78px;
    }
    .largeImg, .magnifier-image{
        min-width: 100%;
        min-height: 100%;
        width: 300px;
        height: 395px;
    }
    .product_image_title_div{
        width: 39%;
        padding: 6% 0%;
    }
    .productTitleText,
    .productTitleBtn, 
    .productTitleBtnText{
        font-size: 20px;
        color:blue;
    }
    .productTitlePrice{
        font-size: 28px;
    }
    .productTitleBtn, .productTitleBtnText{
        font-size: 20px;
    }
    .productTextBox{
        width: 100%;
        padding: 0px;
        flex-direction: row;
    }
    .firstSpacer{
        width: 17%;
    }
    .productTextParagraph{
        width: 53%;
        font-size: 18px;
    }
    .secondSpacer{
        width: 30%;
    }
    .MProcessTitle{
        padding: 3% 0%;
        text-align: center;
    }
    .mProcessImgOne,
    .mProcessImgTwo{
        width: 200px;
        height: 150px;
    }
    .MProcessCaption {
        width: 193px;
        font-size: 15px;
        text-align: center;
    }
    .MProcessSpacer{
        width: 16%;
    }
    .MProcessImgBox{
        width: 54%;
    }
    .MProcessArtistBox{
        width: 30%;
    }
    .similarImg{
        width: 97%;
    }
}
    /* Laptop   */
@media (min-width : 1251px ) and (max-width: 1300px){}
/* Laptop over inches13-2  */
@media (min-width: 1301px) and (max-width: 1400px) {}