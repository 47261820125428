@import './app/mixin.scss';

.auth-container {
  // min-height: 100vh;
  justify-content: space-around;
  padding: 0;
  margin: 0;
  width: 100%;
  overflow: hidden;
}

.auth-form-container {
  max-width: 450px;
  // position: absolute;
  // left: 0;
  // right: 0;
  // top: 0;
  // bottom: 0;
  // margin: auto;
  // color: #495376;
  // background: #000;
  // box-shadow: 5px 0px 11px rgba(0, 0, 0, 0.25);
  // min-height: 100vh;
}

.form-container {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  color: #495376;
  background: #000;
  box-shadow: 5px 0px 11px rgba(0, 0, 0, 0.25);
}

.auth-form-input {
  @include auth-form-input;
}

.auth-form-input-absolute {
  @include auth-form-input(!important);
}

// .auth-form-input::before{
//   content: "";
//   position: absolute;
//   z-index: -1;
//   inset: 0;
//   padding: 1.5px;
//   opacity: 0.5;
//   border-radius: 15px;
//   background: linear-gradient(90deg, #E85EFF 0%, #2DD9FF 100%);
//   -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
//   mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
//   -webkit-mask-composite: xor;
// }

.auth-form-input:hover {
}


// .auth-form-input{
::-webkit-input-placeholder {
  /* Edge */
  color: #ffffff;
  opacity: 0.5;
}

:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #ffffff;
  opacity: 0.5;
}

input::placeholder {
  color: red;
  opacity: 0.5;
}
// }

.auth-form-label {
  text-align: center;
  font-family: "Monoglyceride";
  font-size: 40px;
}

.login-form-input {
  height: 50px;
  left: 668px;
  top: 437px;
  border: 1px solid #495376;
  border-radius: 5px;
  padding: 0 15px;
}

.auth-form-button {
  background: transparent;
  border: 1px solid #ffffff6d;
  border-radius: 15px;
  font-family: "Monoglyceride";
}

.auth-form-button:hover {
  font-size: 30px;
  font-family: "Monoglyceride";
  background: linear-gradient(
    268deg,
    rgba(171, 121, 248, 1) 50%,
    rgba(95, 73, 181, 1) 99.48%
  );
  border: 1px solid #fff0;
  border-radius: 15px;
}

.auth-form-button-label {
  color: #ffffff6d !important;
}

.auth-form-button:hover .auth-form-button-label {
  color: #fff !important;
}

.kardust-font {
  font-family: "Kardust";
}

.kardust-expanded-light-font {
  font-family: "KardustExpandedLight" !important;
  font-size: 16px ;
}

.kardust-light-font {
  font-family: "Kardust" !important;
  font-size: 20px !important;
  font-weight: 500;
  line-height: 30px;
}

.Monoglyceride-font {
  font-family: "Monoglyceride" !important;
}

.term-policy {
  cursor: pointer;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently*/
}

.term-policy-p {
  max-height: 50vh;
  overflow: scroll;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.term-policy-p::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.term-policy-p {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.accept-btn {
  justify-content: space-evenly;
  opacity: 0.9;
  background: url(../assets/images/Rectangle.png),
    linear-gradient(
      180deg,
      rgba(255, 255, 255, 0.105) -442.31%,
      rgba(255, 255, 255, 0) 214.62%
    );
  /* backdrop-filter: blur(25px); */
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  bottom: 20px;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  text-align: center;
  border-radius: 15px;
}

.accept-btn:hover {
  margin: auto;
  font-size: 30px;
  font-family: "Monoglyceride";
  background: linear-gradient(
    268deg,
    rgba(171, 121, 248, 1) 50%,
    rgba(95, 73, 181, 1) 99.48%
  );
  margin: auto;
  border-radius: 15px;
}

.accept-btn:hover .auth-form-button-label {
  color: #fff !important;
}

.notifier-btn {
  font-size: 30px;
  font-family: "Monoglyceride";
  background: linear-gradient(
    268deg,
    rgb(171, 121, 248) 50%,
    rgb(95, 73, 181) 99.48%
  );
  border: 1px solid rgba(255, 255, 255, 0);
  border-radius: 15px;
}

.notifier-wrapper {
  background: #19162d;
  mix-blend-mode: normal;
}

.swal2-popup {
  grid-column: 2;
  grid-row: 2;
  align-self: center;
  justify-self: center;
  // width: 95%;
  color: rgb(255, 255, 255);
  display: grid;
  border-radius: 15px !important;
}

.swal2-popup::before{
    content: "";
    position: absolute;
    inset: 0;
    padding: 1.5px;
    opacity: 0.5;
    border-radius: 15px;
    background: linear-gradient(90deg, #E85EFF 0%, #2DD9FF 100%);
    -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
    mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
    -webkit-mask-composite: xor;
    mask-composite: exclude;
}

.swal2-html-container{
  text-align: justify !important;
  // margin-top: 10px;
}

.swal2-styled.swal2-confirm{
}

.swal2-icon{
  border-color: #0000;
}

.btn-confirm{
  width: 200px;
  font-family: "Monoglyceride" !important;
  font-size: 20px !important;
}

.bottom-modal{
  bottom: 0;
  left: 0;
  background: linear-gradient(192.05deg, #4B225C 0%, #19162D 23.44%, #19162D 72.92%, #153059 100%);
  border-radius: 15px 15px 0px 0px;
  width: 100%;
}

.background-blur{
  background: rgba(25, 22, 45, 0.8);
  mix-blend-mode: normal;
  backdrop-filter: blur(15px);
  width: 100%;
  min-height: 100vh;
  top: 0;
}

.cancel-icon{
  top: 20px;
  right: 20px;
}

.pincode-input-container
{
  .pincode-input-text
  {
  padding: 0px;
  margin: 0px 5px;
  text-align: center;
  background: transparent;
  width: 35px;
  height: 50px;
  border: double 3px transparent;
  border-radius: 6px;
  background-image: linear-gradient(white, white), 
                    linear-gradient(to right, orange, yellow);
  background-origin: border-box;
  background-clip: padding-box, border-box;
  
}

}
.pincode-input-container
{
  .pincode-input-text::before
{
  // border: double 3px transparent;
  // border-radius: 6px;
  // background-image: linear-gradient(white, white), 
  //                   linear-gradient(to right, orange, yellow) !important;
  // background-origin: border-box;
  // background-clip: padding-box, border-box;

  content: "";
    position: absolute;
    inset: 0;
    padding: 1.5px;
    opacity: 0.5;
    border-radius: 15px;
    background: linear-gradient(90deg, #E85EFF 0%, #2DD9FF 100%);
    -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);

  // content: "";
  // position: absolute;
  // z-index: -1;
  // inset: 0;
  // padding: 1.5px;
  // opacity: 0.5;
  // border-radius: 15px;
  // background: linear-gradient(90deg, #E85EFF 0%, #2DD9FF 100%);
  // -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
  // mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
  // -webkit-mask-composite: xor;
}
}

.bottom-modal::before{
  content: "";
  position: absolute;
  inset: 0;
  padding: 1.5px;
  opacity: 0.5;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  background: linear-gradient(90deg, #E85EFF 0%, #2DD9FF 100%);
  -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
  mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
  -webkit-mask-composite: xor;
  mask-composite: exclude;
}

////
.login-form-forget-password {
  font-family: "Noto Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #3a3b41ac;
  cursor: pointer;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently supported by Chrome, Edge, Opera and Firefox */
}

.half-white {
  color: #ffffff6d !important;
  // opacity: 0.5;
}

.min-height-40 {
  min-height: 40vh;
}

.form-button {
  width: 250px;
  height: 50px;
  left: 760px;
  top: 763px;

  background: #61ad89;
  border-radius: 5px;
  color: white;
  border: 0;
}

.form-img-background {
  background-image: url("https://static.photocdn.pt/images/articles/2019/09/10/Best_Laptops_for_Photo_Editing_in_2019-1.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
}

.login-option-button {
  background: #ffffff;
  border: 2px solid #495376;
  border-radius: 10px;
  width: 40%;
  margin: 10px auto;
  text-align: center;
  width: 70%;
  cursor: pointer;
}

.login-option-button:hover {
  background: #495376;
  color: #fff;
}

.login-option-label {
  font-family: "Noto Sans JP";
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 35px;
  color: #495376;
}

.sign-up-button {
  visibility: hidden; // web3auth
  pointer-events: none; // web3auth
  margin: auto;
  font-size: 30px;
  font-family: "Monoglyceride";
  background: linear-gradient(
    268deg,
    rgba(171, 121, 248, 1) 50%,
    rgba(95, 73, 181, 1) 99.48%
  );
  border: 0;
  margin: auto;
  border-radius: 15px;
}

.create-account-button {
  font-family: "Monoglyceride";
  font-size: 30px;
  color: linear-gradient(90deg, #e85eff 0%, #2dd9ff 100%);
  box-shadow: 0 0 6px 0 rgba(157, 96, 212, 0.5);
  border: solid 2px transparent;
  background-image: linear-gradient(
      rgba(255, 255, 255, 0),
      rgba(255, 255, 255, 0)
    ),
    linear-gradient(90deg, #e85eff, #2dd9ff);
  background-origin: border-box;
  background-clip: content-box, border-box;
  box-shadow: 2px 1000px 1px #000 inset;
  border-radius: 15px;
}

.sign-in-label {
  /* Create the gradient. */
  background-image: linear-gradient(
    90deg,
    rgba(232, 94, 255, 1) 50%,
    rgba(45, 217, 255, 1) 100%
  );

  /* Set the background size and repeat properties. */
  background-size: 100%;
  background-repeat: repeat;

  /* Use the text as a mask for the background. */
  /* This will show the gradient as a text color rather than element bg. */
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  -moz-background-clip: text;
  -moz-text-fill-color: transparent;
}

.create-account-label {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 29px;
  color: #495376;
}

.form-right-col {
  display: flex;
  flex-flow: column;
  z-index: 1;
  height: 40%;
}

.bg-video-container {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 60%;
  .my-video {
    top: 0 !important;
    left: 0 !important;
  }
}

.member-register-container {
  width: 340px;
  height: 480px;
  background: #ecf3ff;
  border-radius: 20px;
  position: relative;
  margin: 30px;
  display: flex;
  flex-flow: column;
}

.member-register-label {
  font-weight: 500;
  font-size: 24px;
  line-height: 43px;
  color: #495376;
}

.member-register-option {
  position: absolute;
  top: -20px;
  left: 45%;
  width: 40px;
  height: 40px;
  background: #495376;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  font-weight: 400;
  font-size: 21px;
  padding-top: 3px;
  color: #ffffff;
}

.member-register-body {
  display: flex;
  flex-flow: column;
  height: 75%;
  justify-content: center;
}

.member-register-option-label {
  font-weight: 400;
  font-size: 18px;
  line-height: 32px;
  color: #495376;
  text-align: center;
  margin: 30px 0;
}

.member-register-option-button {
  width: 250px;
  height: 50px;
  background: #61ad89;
  border-radius: 5px;
  border: 0;
  margin: 0 auto;
  color: #fff;
}

.button-default {
  background: #495376;
}

.meta-mask-method {
  font-weight: 400;
  font-size: 16px;
  line-height: 23px;
  color: #495376;
}

.meta-mask-option-button {
  width: 245px;
  height: 55px;
  border-radius: 5px;
  border: 0;
  margin: 0 auto;
  cursor: pointer;
}

.idms-register-button {
  width: 300px;
  height: 50px;
  left: 723px;
  top: 710px;

  background: #61ad89;
  border-radius: 5px;
  border: 0;
  color: #fff;
}

.idms-register-input {
  width: 100%;
  height: 50px;
  background: #ffffff;
  border: 1px solid #495376;
  border-radius: 5px;
}

.idms-register-label {
  font-weight: 500;
  font-size: 18px;
  line-height: 30px;
  text-align: center;
  color: #495376;
}

.idms-register-step-label {
  font-weight: 600;
  font-size: 13px;
  line-height: 20px;

  color: #495376;
}

.idms-register-step-number {
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  font-weight: 400;
  font-size: 21px;
  color: #fff;
  padding-top: 3px;
}

.current-step {
  background: #495376;
}

.complete-step {
  background: #61ad89;
}

.idms-register-step-four-label {
  font-weight: 600;
  font-size: 18px;
  line-height: 27px;

  color: #495376;
}

.notifier {
  align-self: self-end;
  font-weight: 400;
  font-size: 15px;
  line-height: 22px;
  color: rgba(73, 83, 118, 0.5);
}

.h-90 {
  height: 90%;
}

.jp_lang {
  .auth-form-label {
    font-size: 25px !important;
  }
}