$navHeight: 67px;
$subNavHeight: 46px;
$heightWithoutNav: calc(100vh - $navHeight);
$heightWithoutNav2: calc(100dvh - $navHeight);
$sidebarHeight: 1040px;
$mypageSidebarHeight: 1000px;
$sidebarWidth: 237px;
$sidebarFlexWidth: 0 0 260px;
$sidebarFilterFlexWidth: 0 0 360px;
$widthWithoutSidebar: calc(100vw - $sidebarWidth);
$bottomNavHeight: 62px;
$subNavHeight: 62px;
