@import 'color.scss';
@import 'mixin.scss';
@import 'common.scss';
@import 'breakpoint.scss';

$max: 12;
$angle: 360/$max;
$size: 120px;
$fill: $badge-color;

.l-wrapper {
  position: fixed;
  width: $widthWithoutSidebar;
  height: $heightWithoutNav;
  @include flex-center;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
  text-align: center;


  .lds-default {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;
    // margin-left:-3px;

    div {
      position: absolute;
      width: 14.63px;
      height: 14.63px;
      // background: #fff;
      border-radius: 50%;
      animation: lds-default 1.2s linear infinite;;
    }

    div:nth-child(1) {
      animation-delay: -0.3s;
      top: 7px;
      left: 37px;
      background: #9096fe;
    }

    div:nth-child(2) {
      animation-delay: -0.4s;
      top: 11px;
      left: 22px;
      background: #9d8ffc;
    }

    div:nth-child(3) {
      animation-delay: -0.5s;
      top: 22px;
      left: 11px;
      background: #b083fe;
    }

    div:nth-child(4) {
      animation-delay: -0.6s;
      top: 37px;
      left: 7px;
      background: #a986ff;
    }

    div:nth-child(5) {
      animation-delay: -0.7s;
      top: 52px;
      left: 11px;
      background: #998ef9;
    }

    div:nth-child(6) {
      animation-delay: -0.8s;
      top: 62px;
      left: 22px;
      background: #8899ff;
    }

    div:nth-child(7) {
      animation-delay: -0.9s;
      top: 66px;
      left: 37px;
      background: #6a92fc;
    }

    div:nth-child(8) {
      animation-delay: -1s;
      top: 62px;
      left: 52px;
      background: #698ee7;
    }

    div:nth-child(9) {
      animation-delay: -1.1s;
      top: 52px;
      left: 62px;
      background: #5a8ce0;
    }

    div:nth-child(10) {
      animation-delay: 0s;
      top: 37px;
      left: 66px;
      background: #5583c3;
    }

    div:nth-child(11) {
      animation-delay: -0.1s;
      top: 22px;
      left: 62px;
      background: #4c71b0;
    }

    div:nth-child(12) {
      animation-delay: -0.2s;
      top: 11px;
      left: 52px;
      background: #4561a1;
    }

    // @for $i from 1 through 12 {
    //   div:nth-child(#{$i}) {
    //     transform: scale((13-$i)/12);
    //   }
    // }
  }

  .levias-lds-default {
    div:nth-child(1) {
      animation-delay: -0.3s;
      top: 7px;
      left: 37px;
      background: #44A59E !important;
    }

    div:nth-child(2) {
      animation-delay: -0.4s;
      top: 11px;
      left: 22px;
      background: #44A59E !important;
    }

    div:nth-child(3) {
      animation-delay: -0.5s;
      top: 22px;
      left: 11px;
      background: #44A59E !important;
    }

    div:nth-child(4) {
      animation-delay: -0.6s;
      top: 37px;
      left: 7px;
      background: #44A59E !important;
    }

    div:nth-child(5) {
      animation-delay: -0.7s;
      top: 52px;
      left: 11px;
      background: #44A59E !important;
    }

    div:nth-child(6) {
      animation-delay: -0.8s;
      top: 62px;
      left: 22px;
      background: #44A59E !important;
    }

    div:nth-child(7) {
      animation-delay: -0.9s;
      top: 66px;
      left: 37px;
      background: #44A59E !important;
    }

    div:nth-child(8) {
      animation-delay: -1s;
      top: 62px;
      left: 52px;
      background: #44A59E !important;
    }

    div:nth-child(9) {
      animation-delay: -1.1s;
      top: 52px;
      left: 62px;
      background: #44A59E !important;
    }

    div:nth-child(10) {
      animation-delay: 0s;
      top: 37px;
      left: 66px;
      background: #44A59E !important;
    }

    div:nth-child(11) {
      animation-delay: -0.1s;
      top: 22px;
      left: 62px;
      background: #44A59E !important;
    }

    div:nth-child(12) {
      animation-delay: -0.2s;
      top: 11px;
      left: 52px;
      background: #44A59E !important;
    }
  }

  @include media-breakpoint-up("lg") {
    position: absolute;
  }

}


@keyframes lds-default {
  // @for $i from 1 through 10 {
  //   #{(10-$i) * 10%} {
  //     transform: scale($i/10);
  //   } 
  // }

  0% {
    transform: scale(1);
  }

  100% {
    transform: scale(0);
  }
}

@keyframes colors {

  100% {
    background: #FF0000;
  }

  90% {
    background: #FF7A00;
  }

  80% {
    background: #FFD600;
  }

  70% {
    background: #DBFF00;
  }

  60% {
    background: #05FF00;
  }

  50% {
    background: #00FFB2;
  }

  40% {
    background: #00D1FFE5;
  }

  30% {
    background: #0085FFCC;
  }

  25% {
    background: #2400FFB2;
  }

  20% {
    background: #DB00FF99;
  }

  15% {
    background: #FF006B80;
  }
}

@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}